<template>
  <div>
    <p>Booking Calendar 尚未開發</p>
  </div>
</template>

<script>
export default {
  data() {
    return {
      booking: null,
    }
  },
}
</script>
